import SendProgress from 'SendBlast/components/SendProgress'
import Loading from 'Shared/components/Loading/Loading'
import StatList from 'Shared/components/StatList'
import useExtraActions from 'CampaignOverview/useAct'
import useScriptActions from 'Shared/ScriptEditor/hooks/useAct'
import { useWindowSize } from 'usehooks-ts'
import { LARGE_SCREEN_WIDTH } from 'Shared/constants'

import ExportConfirmationModal from 'Shared/components/ExportConfirmationModal'
import SmsPreview from 'CampaignBuilder/components/Steps/SmsPreview'
import './TextOverview.scoped.scss'
import restClient from 'Shared/hooks/restClient'
import { useEffect, useState } from 'react'
import userCan from 'Shared/functions/userCan'
import FloatingMenu from 'Shared/components/FloatingMenu/FloatingMenu'
import IconBox from 'Shared/components/IconBox'
import OverviewStats from 'CampaignStats/components/OverviewStats'
import VariantStats from './VariantStats';
import TextMessageContentTabs from 'CampaignBuilder/components/Steps/TextMessageContentTabs';
import SimplePaginator from 'Shared/components/SimplePaginator'
import Boxes from 'Shared/components/Boxes'
import GoalBar from './GoalBar'

function formatDate(dateString) {
  if (!dateString) return ''
  return (new Date(dateString)).toLocaleDateString('default')
}

const CAMPAIGN_LABELS = {
  whatsapp: {
    sms_blast: 'WhatsApp blast',
    sms_survey: 'WhatsApp survey',
    sms_conversations: 'WhatsApp conversations',
  },
  sms: {
    sms_blast: 'Text blast',
    sms_survey: 'Text survey',
    sms_conversations: 'Text conversations',
  },
}

function prettify(body, mergeTags) {
  let prettyBody = '<span>' + body.replaceAll('\n', '</span><span>') + '</span>'
  for (const [slug, tag] of Object.entries(mergeTags)) {
    prettyBody = prettyBody.replaceAll(
      `{{${slug}}}`,
      `<span class="merge-tag blue">${tag}</span>`
    )
  }
  return prettyBody
}

function SummaryItem({ icon, label, value, large = false, link = '' }) {
  const path = `/admin/searches/${link}`;
  return (
    <div className={`summary-item ${large ? 'large' : 'small'}`}>
      <IconBox size={large ? 132 : 56}>{icon}</IconBox>
      <div>
        {!link && <h3 className={`${large ? 'large' : ''}`}>{value}</h3>}
        {link && <a className={`${large ? 'large' : ''}`} href={path}>{value}</a>}
        <p>{label}</p>
      </div>
    </div>
  )
}

function Preview({ message, renderedBody, textMessageBlast }) {
  return <div>
    <h2>{message} Preview</h2>
    <TextMessageContentTabs />
    <SmsPreview
      name={'Test'}
      content={{ body: renderedBody }}
      kind={textMessageBlast?.kind}
      actions={[]}
    />
  </div>
}

function Links({ links }) {
  return <div>
    <h2>Links</h2>
    {links.length === 0 && <p>There's no tracked links in this message.</p>}
    {links.length > 0 && <ul>
      {links.map(({ url, count }) => (
        <li key={url}>
          <strong>{count}</strong> - {url}
        </li>
      ))}
    </ul>}
  </div>
}

function formatPercent(value, total) {
  const percentage = (total === 0 || !total) ? 0 : ((value || 0) / total * 100).toFixed()
  return `${percentage}%`
}

export default function TextOverview() {
  const act = useAct()
  const scriptActions = useScriptActions()
  const extraActions = useExtraActions()
  const [mergeTags, setMergeTags] = useState(null)
  const campaignId = useSel((s) => s.local.campaignId)
  const textMessageBlastId = useSel((s) => s.local.textMessageBlastId)
  const textMessageBlast = useSel(s => s.campaign?.textMessageBlast)
  const contentVariants = useSel(s => s.campaign?.textMessageBlast?.contentVariants || [])
  const currentContentVariantId = useSel(s => s.local.currentContentVariantId)
  const [overviewPage, setOverviewPage] = useState(1)
  const size = useWindowSize()

  const questions = useSel(s => _.values(s.questions.entities))

  let campaignStats = useSel(s => s.campaign?.stats || [{}])

  // TODO: REMOVE when migrated
  // backwards compatibility with old stats saved in DB
  if (!_.isArray(campaignStats)) {
    campaignStats = [campaignStats]
  }

  // aggregate all numeric stats from variants
  const {
    textMessagesCount,
    failedCount,
    deliveredCount,
    readCount,
    repliedCount,
    surveysCount,
    completedCount,
    clickedCount,
    outstandingConversationsCount,
    unsubscribeCount,
    targetsCount,
    goalCompletionsCount,
    sentWider,
  } = _.reduce(campaignStats, (acc, stats) => _.mapValues(acc, (value, key) => value + stats[key]), {
    textMessagesCount: 0,
    failedCount: 0,
    deliveredCount: 0,
    readCount: 0,
    repliedCount: 0,
    surveysCount: 0,
    completedCount: 0,
    clickedCount: 0,
    outstandingConversationsCount: 0,
    unsubscribeCount: 0,
    targetsCount: 0,
    goalCompletionsCount: 0,
    sentWider: false,
  })

  // aggregate all link stats
  const links = _.chain(campaignStats)
    .flatMap('links')
    .groupBy('url')
    .map((group, url) => ({
      url,
      count: _.sumBy(group, 'count')
    }))
    .value();

  // campaign metadata
  const goalName = useSel((s) => s.campaign?.settings?.goalName)
  const { campaignType, subscriptionTopic, surveyId } = useSel(s => s.campaign || {})
  if (subscriptionTopic !== 'default') {
    const kind = textMessageBlast?.kind === 'whatsapp' ? 'whatsapp' : 'sms'
    var subscriptionTopicLabel = window.ENV.AVAILABLE_SUBSCRIPTIONS[kind].find(sub => sub.topic === subscriptionTopic)?.label
  }

  const canExport =
    _.includes(['sms_conversations', 'sms_blast'], campaignType) && userCan('export_data')
  const canExportMessages = campaignType === 'sms_conversations'
  const [exportPassword, setExportPassword] = useState(null)
  const body =
    campaignType === 'sms_survey'
      ? (questions.length > 0 ? questions[0].question : '')
      : ((contentVariants.find(cv => cv.id === currentContentVariantId) || {}).content?.body || '')
  const renderedBody = mergeTags ? prettify(body || '', mergeTags) : ''
  const { name: searchName, id: searchId, targetCount } = useSel(s => s.campaign?.search || {})
  const senderType = textMessageBlast?.senderType || 'phone_number'
  const sendingInProgress = textMessageBlast && textMessageBlast.finishedSendingAt === null
  const allFailed = !sendingInProgress && failedCount === textMessagesCount && deliveredCount === 0
  const previewComponents = [<Preview message="Text" renderedBody={renderedBody} textMessageBlast={textMessageBlast} />].concat(links ? [<Links links={links} />] : [])

  // load associated workflows
  const [workflows, setWorkflows] = useState([])
  useEffect(() => {
    restClient
      .get(`/api/campaigns/${campaignId}/workflows`)
      .then(({ data }) => setWorkflows(data))

    act.local.loadMergeTags().then(({ data }) => {
      setMergeTags(data)
    })
  }, [])

  // load script & question stats
  useEffect(() => {
    if (surveyId) {
      scriptActions.scriptBuilder.load(surveyId)
      const questionInterval = setInterval(() => scriptActions.scriptBuilder.load(surveyId), 5000)

      return () => {
        clearInterval(questionInterval)
      }
    }
  }, [surveyId])

  const doExport = (conversations) => {
    if (conversations) {
      extraActions.campaignStats.emailConversationsExport().then(setExportPassword)
    } else {
      extraActions.campaignStats.emailExport().then(setExportPassword)
    }
  }

  if (!textMessageBlast) {
    return <Loading text="Loading" />
  }

  const menuItems = [
    { name: 'Summary', tag: 'summary' },
    { name: 'Analytics', tag: 'analytics' },
    { name: 'Preview', tag: 'preview' },
  ]
  if (sendingInProgress) {
    menuItems.splice(1, 0, { name: 'Progress', tag: 'progress' })
  }
  if (campaignType === 'sms_survey') {
    menuItems.push({ name: 'Questions', tag: 'questions' })
  }

  const message = textMessageBlast?.kind === 'whatsapp' ? 'Message' : 'Text'

  const stats = [
    { value: textMessagesCount || 0, label: message + 's sent' },
    { value: clickedCount || 0, label: message + 's clicked' },
    { value: formatPercent(clickedCount, textMessagesCount), label: 'Click rate' },
    { value: failedCount || 0, label: message + 's failed' },
    { value: unsubscribeCount || 0, label: 'Unsubscribed' },
  ]
  if (campaignType === 'sms_conversations') {
    stats.push({ value: formatPercent(repliedCount, textMessagesCount), label: 'Reply rate' })
  }
  if (campaignType === 'sms_survey') {
    stats.push({ value: formatPercent(completedCount, textMessagesCount), label: 'Completion rate' })
  }

  function processSendPhaseStats(sendPhaseStats) {
    return sendPhaseStats.map((stats, i) => {
      const {
        contentVariantId,
        textMessagesCount,
        repliedCount,
        clickedCount,
        unsubscribeCount,
        sentWider,
      } = stats

      const calculateRate = (count) => {
        return textMessagesCount > 0 ? parseFloat((100 * count / textMessagesCount).toFixed(1)).toString() : "0";
      }

      return {
        id: contentVariantId,
        clickRate: calculateRate(clickedCount),
        textMessagesCount,
        unsubscribeCount,
        replyRate: calculateRate(repliedCount),
        sentWider,
      }
    })
  }

  const sendPhases = _.map(_.groupBy(campaignStats, 'sendPhase'), (sendPhaseStats, sendPhase) => {
    return {
      sendPhase,
      stats: processSendPhaseStats(sendPhaseStats)
    }
  })

  const hasTests = _.some(_.values(sendPhases), variantStats => variantStats.stats.length > 1)

  return (
    <div className="overview">
      <div className="left">
        {size.width >= LARGE_SCREEN_WIDTH &&
          <FloatingMenu items={menuItems} />
        }
        {size.width < LARGE_SCREEN_WIDTH &&
          <SimplePaginator
            totalPages={3}
            currentPage={overviewPage}
            onChange={setOverviewPage}
            labels={menuItems.map(item => item.name)}
            classes='margin-bottom' />
        }
      </div>
      <div className="right">

        {(size.width >= LARGE_SCREEN_WIDTH || overviewPage === 2) && (
          <div id="summary">
            <div className="box">
              <SummaryItem label="Recipients" value={(targetsCount || targetCount || 0).toLocaleString('en-US')} icon={<SvgIconsAudience />} large />
            </div>
            <div className="box">
              <SummaryItem label="Campaign Type" value={CAMPAIGN_LABELS[textMessageBlast?.kind][campaignType]} icon={<SvgIconsSmsBlast />} />
              <SummaryItem label="Audience" link={searchId} value={searchName} icon={<SvgIconsAudience />} />
              {subscriptionTopicLabel && <SummaryItem label="Subscription Topic" value={subscriptionTopicLabel} icon={<SvgIconsQuote />} />}
            </div>
            <div className="box">
              <SummaryItem label="Sent" value={textMessageBlast?.sentAt ? formatDate(textMessageBlast?.sentAt) : 'Now'} icon={<SvgIconsCalendar />} />
              <SummaryItem
                label={senderType === 'phone_number' ? 'From' : 'Sender Name'}
                value={senderType === 'phone_number' ? 'Phone number' : textMessageBlast?.senderName}
                icon={<SvgIconsUser />} />
            </div>
          </div>
        )}

        {(size.width >= LARGE_SCREEN_WIDTH || overviewPage === 1) && <>
          <div id="progress" className="box">
            {sendingInProgress && (
              <SendProgress
                campaignId={campaignId}
                leftAligned={false}
                selfContained={true}
                entityType="textMessageBlast"
                entityId={textMessageBlastId}
              />
            )}
            {!sendingInProgress && <h1 className={`${canExport ? '' : 'no-margin-bottom'}`}>Sending complete.</h1>}

            {allFailed && <p className="error">All of your texts failed to send. Please get in touch with support.</p>}

            {canExport && (
              <div className="margin-top">
                <a
                  onClick={() => doExport(false)}
                  className="button small primary margin-right"
                >
                  <SvgIconsDownload /> Export results
                </a>
                {canExportMessages && (
                  <a onClick={() => doExport(true)} className="button small secondary">
                    <SvgIconsDownload /> Export all messages
                  </a>
                )}

                <ExportConfirmationModal
                  exportPassword={exportPassword}
                  setExportPassword={setExportPassword}
                  entityName="campaign" />
              </div>
            )}
          </div>

          {goalName && <GoalBar goalName={goalName} goalCompletionsCount={goalCompletionsCount} />}
        </>}

        {(size.width >= LARGE_SCREEN_WIDTH || overviewPage === 2) && <>
          <div id="analytics" className="box">
            <StatList stats={stats} />
          </div>

          {hasTests && sendPhases.map(({ sendPhase, stats }) => {
            return <div key={sendPhase}>
              <div className="box">
                <VariantStats variants={stats} sendPhase={sendPhase} />
              </div>
            </div>
          })}
        </>}

        {(size.width >= LARGE_SCREEN_WIDTH || overviewPage === 3) && (
          <Boxes
            id="preview"
            boxes={previewComponents}
          />
        )}

        {campaignType === 'sms_survey' && <div id="questions"><OverviewStats onlyQuestions={true} /></div>}
      </div>
    </div>
  )

}
