import { tags } from 'mustache'
import { renderToString } from 'react-dom/server'
import { format, parseISO } from "date-fns"

const renderStat = (value, label, percentage = true) =>
  `<span class="stat-percentage">${value.toFixed(0)}${percentage ? '%' : ''}</span><br /><span class="stat-label">${label}</span>`

const columns = [
  {
    data: 'bookmarked',
    className: 'toggle-bookmark',
    orderable: false,
    render: (data, type, row) => {
      return renderToString(
        <button data-id={row.id} className='toggle-bookmark button naked'>
          <SvgIconsBookmarkStar solid={data === 'true'} />
        </button>
      )
    },
  },
  {
    data: 'name',
    className: 'bold verticalAlign',
    width: '35%',
    render: function(data, type, row, meta) {
      const ellipsisClass = row['status'] === 'draft' ? 'accent' : 'active'
      const tags = _.compact(decodeURIComponent(row['tags'] || '').split('||'))
      const date = row['date'] && row['status'] !== 'draft' ? format(parseISO(row['date'].replace(' ', 'T').replace(' UTC', 'Z')), "do MMMM yyyy, HH:mm") : ''
      const prefix = row['primary_type'] === 'Phone' ? 'Launched at:' : 'Sent at:'

      return renderToString(<div>
        <a href={row['url']}>{data}</a><br />
        {row['date'] && row['status'] !== 'draft' && <><span className='small-text'>{prefix} {date}</span><br /></>}
        {tags.map(tag => <span className='tag' key={tag}>{tag}</span>)}
      </div>
      )
    },
  },
  {
    data: 'type',
    className: 'light',
    width: '26%',
    orderable: false,
    render: function(data, type, row, meta) {
      if (row['secondary_type']) {
        return `<span>${row['primary_type']}</span><span class='ellipsis'></span><span>${row['secondary_type']}</span>`
      } else {
        return row['primary_type']
      }
    },
  },
  {
    data: 'status',
    width: '8%',
    orderable: false,
    render: function(data, type, row, meta) {
      function getClass() {
        const status = row['status']
        const approvalStatus = row['approval_status']
        if (approvalStatus === 'awaiting_approval') return 'accent'
        if (approvalStatus === 'rejected') return 'red'
        if (status === 'live' || status === 'sent') return 'accent'
        if (status === 'closed') return 'blue-100'
        if (status === 'aborted') return 'red'

        return 'purple-60'
      }

      if (row['approval_status'] === 'awaiting_approval') {
        var status = 'Awaiting approval'
      } else if (row['approval_status'] === 'rejected') {
        var status = 'Approval rejected'
      } else {
        var status = row['status']
      }

      return `<span class="bold ${getClass()} capitalize">${status}</span>`
    },
  },
  {
    data: 'recipients',
    className: 'light',
    width: '7%',
    orderable: false,
    render: function(data, type, row, meta) {
      const count = Number(row['recipients']).toLocaleString()
      return `<span class="dark-grey">${count}</span>`
    }
  },
  {
    data: 'id',
    className: 'datatable-stat',
    width: '12%',
    orderable: false,
    render: (data, type, row) => {
      const canSum = _.isArray(row.stats)
      if (!canSum) {
        var statsRow = row.stats
      } else {
        var statsRow = row.stats.find(stats => !stats.send_phase) || row.stats[0]
      }

      if (row.status === 'draft') {
        return ''
      }
      let value = 0
      let label = ''
      let percentage = true
      if (row.type === 'sms_blast') {
        const clicks = canSum ? row.stats.reduce((acc, stats) => acc + stats.clicked_count, 0) : statsRow.clicked_count
        const messages = canSum ? row.stats.reduce((acc, stats) => acc + stats.text_messages_count, 0) : statsRow.text_messages_count
        value = clicks / messages * 100
        label = 'Clicks'
      }
      if (row.type === 'sms_conversations') {
        value = canSum ? row.stats.reduce((acc, stats) => acc + stats.outstanding_conversations_count, 0) : statsRow.outstanding_conversations_count
        label = 'To reply to'
        percentage = false
      }
      if (row.type === 'sms_survey') {
        value = statsRow.surveys_count
        label = 'Surveys begun'
        percentage = false
      }
      if (row.type === 'phone') {
        value = statsRow.total_calls
        label = 'Calls made'
        percentage = false
      }
      if (row.type === 'email') {
        if (statsRow.emails_count === 0) {
          value = 0
        } else {
          value = (statsRow.opened_count / statsRow.emails_count) * 100
          label = 'Opened'
        }
      }
      if (isNaN(value)) {
        return '&nbsp;'
      }
      return renderStat(value, label, percentage)
    },
  },
  {
    data: 'id',
    className: 'datatable-stat',
    width: '12%',
    orderable: false,
    render: (data, type, row) => {
      if (!_.isArray(row.stats)) {
        var statsRow = row.stats
      } else {
        var statsRow = row.stats.reduce((acc, curr) => {
          Object.keys(curr).forEach(key => {
            if (typeof curr[key] === 'number') {
              acc[key] = (acc[key] || 0) + curr[key];
            } else if (Array.isArray(curr[key])) {
              acc[key] = [...(acc[key] || []), ...curr[key]];
            } else if (!(key in acc)) {
              acc[key] = curr[key];
            }
          });
          return acc;
        }, {});
      }

      if (row.status === 'draft') {
        return ''
      }
      let value = 0
      let label = ''
      let percentage = true
      // show reply rate for SMS blasts and conversations
      if ((row.primary_type === 'Text Message' && row.type === 'sms_blast') || row.type === 'sms_conversations') {
        value = (statsRow.replied_count / statsRow.text_messages_count) * 100
        label = 'Replies'
      }
      // however for WhatsApp blasts, we have a read %
      if (row.primary_type === 'WhatsApp' && row.type === 'sms_blast') {
        value = (statsRow.read_count / statsRow.text_messages_count) * 100
        label = 'Read'
      }
      if (row.type === 'sms_survey') {
        value = statsRow.completed_count
        label = 'Surveys completed'
        percentage = false
      }
      if (row.type === 'phone') {
        value = (statsRow.pickup_rate || 0) * 100
        label = 'Pickup rate'
      }
      if (row.type === 'email') {
        if (statsRow.emails_count === 0) {
          value = 0
        } else {
          value = (statsRow.clicked_count / statsRow.emails_count) * 100
          label = 'Clicked'
        }
      }
      if (isNaN(value) || !isFinite(value)) {
        return '&nbsp;'
      }
      return renderStat(value, label, percentage)
    },
  },
  { data: 'created_at', visible: false },
  { data: 'launched_at', visible: false },
]

const headers = [
  '',
  'Name',
  'Campaign Type',
  'Status',
  'Recipients',
  '',
  '',
]
const organisationKinds = JSON.parse(window.ENV.ORGANISATION_KINDS).map(kind => ({
  label: kind.label,
  value: kind.external_type
}));
let filters = [
  {
    name: 'Type', column: 2, kind: 'radio', options: [
      { value: 2, label: 'Text broadcast' },
      { value: 1, label: 'Survey' },
      { value: 3, label: 'Conversation' },
      { value: 0, label: 'Phone' },
      { value: 4, label: 'Email' },
    ]
  },
  {
    name: 'Status', column: 3, kind: 'radio', options: [
      { value: 'draft', label: 'Draft' },
      { value: 'scheduled', label: 'Scheduled' },
      { value: 'live', label: 'Live' },
      { value: 'sent', label: 'Sent' },
      { value: 'paused', label: 'Paused' },
      { value: 'closed', label: 'Closed' },
      { value: 'aborted', label: 'Aborted' }
    ]
  },
  { name: 'Goal', kind: 'async', entity: 'effort' },
  { name: 'Tag', kind: 'async', entity: 'tag' },
  { name: 'Created by', kind: 'async', entity: 'user' },
  { name: 'Organisation', kind: 'async', entity: 'organisation' },
  { name: 'Subscription topic', kind: 'subscription-topic', customParam: 'subscription_topic' },
]

if (organisationKinds.length > 1) {
  filters.push({
    name: 'Organisation type', kind: 'radio-async', entity: 'external_type', options: organisationKinds
  })
}

const sorters = [
  { column: 6, label: 'Launched', order: 'desc' },
  { column: 5, label: 'Created', order: 'desc' },
  { column: 1, label: 'Alphabetically (A-Z)', order: 'asc' },
  { column: 1, label: 'Alphabetically (Z-A)', order: 'desc' }
]

const order = [[6, "desc"], [5, "desc"]]

export { columns, headers, filters, sorters, order }
